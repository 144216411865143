
























































































































































import moment from 'moment';
import Vue from 'vue';
import Moment from '~/components/common/Moment.vue';
import TestPasswordDialog from '~/components/test/TestPasswordDialog.vue';
import { Test, TestGroup, TestInstance } from '~/models';
import { Feedback } from '~/models/Feedback';

export default Vue.extend({
  components: {Moment, TestPasswordDialog},
  name: 'TestDetailPage',
  data() {
    return {
      passwordDialog: false,
      test: null,
      error: null,
    };
  },
  async created() {
    try {
      this.test = await this.$store.dispatch('test/getTestDetails', this.testId);
      this.error = null;
    } catch (err) {
      if (err?.response?.status === 403) {
        this.error = this.$t('error.notAssigned');
      }
    }
  },
  watch: {
    async testId() {
      try {
        this.test = await this.$store.dispatch('test/getTestDetails', this.testId);
        this.error = null;
      } catch (err) {
        if (err?.response?.status === 403) {
          await this.$store.dispatch('snackbar/showError', this.$t('error.notAssigned'));
          this.$router.go(-1);
        }
      }
    },
  },
  computed: {
    student(): boolean {
      return this.$store.state.auth.user;
    },
    isFinal(): boolean {
      return this.test?.type === 'final';
    },
    isProgress(): boolean {
      return this.test?.type === 'progress';
    },
    isPreExam(): boolean {
      return this.test?.type === 'preExam';
    },
    isProtected(): boolean {
      return this.test?.testState === 'protected';
    },
    noTestStartDate(): boolean {
      return !this.$store.state.auth.user.testStartFrom;
    },
    insightMissing(): boolean {
      return this.isPreExam && this.test.instances.reduce((result, instance) => result || !instance.insightGranted, false);
    },
    isUnauthorized(): boolean {
      return this.isPreExam && (!this.test.testState || this.test.testState === 'unauthorized');
    },
    coolDownWaitTime(): any {
      if (this.test.instances.length === 0) {
        return 0;
      }

      const latestInstance = this.test.instances.reduce((latestInstance, currentInstance) => !latestInstance || latestInstance.finishedAt < currentInstance.finishedAt ? currentInstance : latestInstance, null);
      const finishedAtDate = new Date(latestInstance.finishedAt);
      const cooldownInMs = this.test.coolDown * 3600000;
      const cooldownEndDate = new Date(finishedAtDate.getTime() + cooldownInMs);
      const duration = moment.duration(moment(cooldownEndDate).diff(Date.now()));
      return duration.asHours() > 0 ? duration.humanize() : 0;
    },
    testDependants(): Test[] {
      return this.test.dependants.filter((test: Test) =>
          !!this.$store.state.auth.user.tests.find((t: Test) => t.id === test.id)
          || !!this.$store.state.auth.user.testGroups.find((tg: TestGroup) => tg.tests.find(t => t.id === test.id)),
      );
    },
    breadcrumbs(): any[] {
      let testCrumb = {text: this.$t('nav.tests'), to: {name: 'test-overview'}, exact: true};
      if (this.isProgress) {
        testCrumb = {text: this.$tc('p.progressTest', 2), to: {name: 'progress-test-overview'}, exact: true};
      }
      if (this.isFinal) {
        testCrumb = {text: this.$tc('p.finalTest', 2), to: {name: 'final-test-overview'}, exact: true};
      }
      if (this.isPreExam) {
        testCrumb = {text: this.$t('nav.preExams'), to: {name: 'pre-exam-overview'}, exact: true};
      }
      return [
        testCrumb,
        {text: this.test?.displayName || ''},
      ];
    },
    testId(): number {
      return Number(this.$route.params.id);
    },
    instanceHeaders(): any[] {
      return [
        {text: this.$tc('p.try'), value: 'try'},
        {text: this.$t('label.percentage'), value: 'passRate', align: 'right'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers', align: 'right'},
        {text: this.$t('label.result'), value: 'passed', align: 'right'},
        {text: this.$t('label.duration'), value: 'duration', align: 'right', sortable: false},
        {text: this.$t('label.submittedAt'), value: 'finishedAt', align: 'right'},
      ];
    },
    isTestStarted(): boolean {
      return this.$store.state.test.testInstance !== null;
    },
    isPracticeStarted(): boolean {
      return this.$store.state.practice.testInstance !== null;
    },
    isDependentTestPassed(): boolean {
      return !this.test.dependentOn || this.test.dependentOnPassed;
    },
    hasTriesLeft(): boolean {
      return this.test.instances.length < this.test.tries || this.test.extraTry;
    },
    isPassed(): boolean {
      return this.test.instances.reduce((prev: boolean, instance: TestInstance) => prev || instance.passed, false);
    },
    waitForFeedback(): boolean {
      return !!TestInstance.all().find(instance => instance.passed === true && instance.feedback === Feedback.Mandatory && instance.feedbackSubmitted === false);
    },
  },
  methods: {
    async startTest() {
      if (this.test.testState === 'protected') {
        this.passwordDialog = true;
      } else {
        try {
          await this.$store.dispatch('test/start', {testId: this.testId});
          await this.$router.push({name: 'test'});
        } catch (err) {
          let message = err?.response?.data?.message || err?.message;
          if (err?.response?.data?.code === 'ERR_TEST_START_FROM') {
            message = this.$t('msg.error.testStartFrom', {
              startDate: moment(err?.response?.data?.startDate).format('DD.MM.YYYY'),
              endDate: moment(err?.response?.data?.endDate).format('DD.MM.YYYY'),
            });
          }
          await this.$store.dispatch('snackbar/showError', message);
        }
      }
    },
    async practiceTest() {
      await this.$store.dispatch('practice/start', this.testId);
      await this.$router.push({name: 'practice'});
    },
    getPercentage(item: TestInstance) {
      return Math.floor(item.correctAnswers / item.questionAmount * 100);
    },
    getDuration(item: TestInstance) {
      const diff = moment(item.finishedAt).diff(item.createdAt);
      return moment.utc(diff).format('HH:mm:ss');
    },
  },
});
